import React, { useLayoutEffect } from 'react';
import s from './VerifyEmail.module.scss';
import {Dot, Loader} from "./Logo";
import {useUser} from "../utils/user";
import {useProducts} from "../utils/prices";
import {getCurrentProduct, useCurrentSubscriptions} from "../utils/subscription";
import { navigate } from 'gatsby';
import {auth} from "../utils/firebase";
import {track} from "../utils/analytics";
import { onAuthStateChanged, User } from 'firebase/auth';


export function ThankYou() {
  const products = useProducts();
  const user = useUser();
  const subscriptions = useCurrentSubscriptions(user?.uid);
  const currentPlan = getCurrentProduct(subscriptions, products);

  useLayoutEffect(() => {
    // @ts-ignore
    onAuthStateChanged(auth, (user: User) => {
      if (!user) {
        track('redirected_to_signin', { from: 'thank-you' });
        navigate('/signin');
      }
    });
  }, []);

  return <div className={s.verifyEmail}>
    <h1>Thank You<Dot/></h1>
    {currentPlan && <p>
      Your purchase of the {currentPlan?.data?.name} was successful.
      You can now start using your new features.
    </p>}
    <button className='secondary' onClick={() => navigate('/account')}>View Account</button>
    {!currentPlan && <Loader/>}
  </div>
}